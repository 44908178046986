import Base = require("Everlaw/Base");
import BaseRedaction = require("Everlaw/Redaction");
import ElevatedRoleConfirm = require("Everlaw/ElevatedRoleConfirm");
import Geom = require("Everlaw/Geom");
import HighlightColor = require("Everlaw/Review/HighlightColor");
import Is = require("Everlaw/Core/Is");
import { NoteUtil } from "Everlaw/Note";
import RedactionStamp = require("Everlaw/Review/RedactionStamp");
import Rest = require("Everlaw/Rest");
import { PartialSelectionType } from "Everlaw/PartialSelectionType";
import {
    RedactionRectangles,
    ReviewRectangles,
    SingleLocationImageAnnotation,
} from "Everlaw/Review/Highlighting";

export class ImageRedactionRecord extends Base.Object {
    markedText: string[];
    override id: number;
    get className(): string {
        return "ImageRedactionRecord";
    }
    constructor(params) {
        super(params);
        this.markedText = params.markedText;
    }
}

export class ImageRedaction
    extends BaseRedaction.Redaction
    implements SingleLocationImageAnnotation
{
    get className() {
        return "ImageRedaction";
    }
    get redactionType() {
        return NoteUtil.ParentType.ImageRedaction;
    }
    markedTextLines: string[];
    revRect: RedactionRectangles;
    getUrlInsert() {
        return "ImageRedaction";
    }

    override _mixin(params: any) {
        let newRectangles;

        if (Is.defined(params.rectangles)) {
            const rects = params.rectangles;
            newRectangles = new RedactionRectangles(
                this,
                rects.rectangles,
                rects.markedText,
                rects.pageNum,
                rects.partialSelectionType,
                rects.contextRectangles,
            );
            delete params.rectangles;
        }
        if (
            newRectangles
            && this.revRect
            && this.revRect.locationHash() !== newRectangles.locationHash()
        ) {
            Base.remove(this);
        }
        if (newRectangles) {
            this.revRect = newRectangles;
        }

        super._mixin(params);
        if (Is.defined(this.id)) {
            Base.add(this);
        }
        if (Is.defined(params.markedTextLines)) {
            this.markedTextLines = params.markedTextLines;
        }
    }

    getId() {
        return this.id;
    }

    getDocId() {
        return this.docId;
    }

    getType() {
        return this.redactionType;
    }

    getColor() {
        return HighlightColor.COLOR_REDACTION;
    }

    getNotes() {
        return this.notes;
    }

    getReviewRectangles(): ReviewRectangles {
        return this.revRect;
    }

    getScrollLocation() {
        return this.revRect.rectangles[0];
    }

    getPageNum() {
        return this.revRect.pageNum;
    }

    getText() {
        return this.revRect.markedText;
    }

    getLocationHash() {
        return this.revRect.locationHash().concat(`, ${this.docId}`);
    }

    getRedactionStamp() {
        return this.redactionStamp;
    }

    _getMarkedTextLines(): string[] {
        return !!this.revRect.markedText ? [this.revRect.markedText] : this.markedTextLines;
    }

    // How many notes with text are associated with this note?
    numTextNotes(): number {
        return this.notes.length;
    }

    override stringifiableObject() {
        const json = super.stringifiableObject();
        delete json["revRect"];
        json["rectangles"] = this.revRect.stringifiableObject();
        return json;
    }

    /**
     * Commit changes to this image redaction.
     * NOTE: If you are updating this redaction's rectangles (i.e. you're changing
     * where it is positioned on the page), do so by passing the updatedRects parameter, NOT by changing
     * the rectangles directly! In several places we index ReviewRectangles by their rectangles; special
     * handling is necessary in order for those to be correctly updated.
     */
    public commit(updatedRects?: Geom.Rectangle[], stamp?: RedactionStamp) {
        if (updatedRects && this.revRect.partialSelectionType !== PartialSelectionType.FULL) {
            // We only pass updated rects to this method on box redactions resize, not for text
            // redactions. This is a sanity check to detect potential bugs, since box redactions
            // are always full.
            throw Error("Updated rectangles may not have properly updated the partial rectangle");
        }
        if (Is.defined(stamp)) {
            this.redactionStamp = stamp;
        }
        const rectangles = !updatedRects
            ? this.revRect
            : new RedactionRectangles(
                  this,
                  updatedRects,
                  this.revRect.markedText,
                  this.revRect.pageNum,
                  this.revRect.partialSelectionType,
                  updatedRects,
              );
        return Rest.post("documents/saveImageRedaction.rest", {
            // Always has:
            docId: this.docId,
            // If new, doesn't have:
            imageRedactionId: this.id,
            rectangles: JSON.stringify(rectangles.stringifiableObject()),
            notes: JSON.stringify(this.notes),
            stampId: this.redactionStamp.id,
        }).then((data) => {
            // If we have a zero length redaction, the backend will handle the deletion so there's
            // nothing to do here.
            if (!data) {
                return;
            }
            // TODO: We add/publish the redaction here, but saveImageRedaction.rest publishes the updated
            // document, and that also contains our redaction. Document._mixin then calls Base.set
            // with the JSON of our redaction as well. This doesn't actually cause problems since the
            // document stores these redaction by their ID, it just causes lots of extra publish
            // events. Since we use `add`, we ensure that `this` is actually the global redaction,
            // even in the event of a race condition where we finish after the mux notification.
            // Since the document uses Base.set, it will only update these fields. In either
            // case, anyone with a reference to this continues to be valid.
            //
            // If we want to remove this logic and rely on the mux notification, then we'll have
            // to be sure that none of the callers hold onto this note object. We can't remove
            // the publishing logic from the notification side, because then other users won't
            // learn about our new note.
            if (!Is.number(this.id)) {
                this.id = data.id;
                const record = new ImageRedactionRecord({
                    id: this.id,
                    markedText: this._getMarkedTextLines(),
                });
                Base.add(record);
                Base.add(this);
            }
            this._mixin(data);
            Base.publish(this);
        });
    }

    @ElevatedRoleConfirm("removing a highlight")
    remove(callback?: (h: BaseRedaction.Redaction, msg?: string) => void, error?: Rest.Callback) {
        Base.remove(this.notes);
        if (Is.number(this.id)) {
            Rest.post("documents/deleteImageRedaction.rest", { imageRedactionId: this.id }).then(
                (data) => {
                    Base.remove(this);
                    callback && callback(this, data);
                },
                (e) => {
                    error && error(e);
                    throw e;
                },
            );
        } else {
            // This wasn't a saved redaction - we don't have to do any deleting, but we should call the
            // callback!
            callback && callback(this);
        }
    }

    getSelectedText(): string[] {
        const record = Base.get(ImageRedactionRecord, this.id);
        return !!record ? record.markedText : [];
    }

    static deleteByDocumentId(docId: number, uid?: number) {
        const redactions = Base.get(ImageRedaction).filter(
            (r) => r.docId === docId && (!uid || (r.userId && r.userId === uid)),
        );
        Rest.post("documents/bulkImageRedact.rest", {
            docId: docId,
            changes: JSON.stringify(redactions.map((r) => [r.id, null])),
        }).then(() => {
            Base.remove(redactions);
        });
    }
}
