import { CommonIcon, EverColor, Icon, useEllipsisTooltip } from "design-system";
import { pageName as helpPageName } from "Everlaw/Help";
import { Article, fetchSupportArticles, fetchSupportVideos, VideoData } from "Everlaw/HelpUtil";
import * as Project from "Everlaw/Project";
import * as Rest from "Everlaw/Rest";
import * as Server from "Everlaw/Server";
import { HelpMenuOption } from "Everlaw/SmartOnboarding/Components/HelpMenuOption";
import { VideoPlayer } from "Everlaw/SmartOnboarding/Components/VideoPlayer";
import * as Dialog from "Everlaw/UI/Dialog";
import * as React from "react";
import { FC, useEffect, useState } from "react";

interface ResourcesProps {
    onViewAllResourcesClick: () => void;
    onContactSupportClick: () => void;
}

interface SupportData {
    articles: Article[];
    videos: VideoData[];
}

interface SupportRowProps {
    title: string;
    isVideo?: boolean;
}

const SupportRow: FC<SupportRowProps> = ({ title, isVideo }) => {
    const { tooltipComponent, tooltipTargetProps } = useEllipsisTooltip({
        children: title,
        "aria-hidden": true,
    });
    return (
        <div className="support-resource flex-centered clickable-row">
            <div className="flex support-resource__file-type-icon">
                {isVideo ? (
                    <Icon.Video size={20} aria-hidden={true} />
                ) : (
                    <Icon.File size={20} aria-hidden={true} />
                )}
            </div>
            <div
                {...tooltipTargetProps}
                className={tooltipTargetProps.className + " support-resource__title"}
            >
                {title}
            </div>
            {tooltipComponent}
            {!isVideo && (
                <div className="support-resource__link-icon flex-centered">
                    <Icon.ArrowUpRight
                        color={EverColor.EVERBLUE_50}
                        size={20}
                        className="support-resource__link-icon"
                        aria-hidden={true}
                    />
                </div>
            )}
        </div>
    );
};

const LoadingRow: FC = () => {
    return (
        <div className="support-resource flex-centered clickable-row">
            <div className="flex support-resource__file-type-icon">
                <CommonIcon.Loading size={20} aria-hidden={true} />
            </div>
            Loading...
        </div>
    );
};

const fetchSupportResources: () => Promise<SupportData> = async () => {
    const articles = await fetchSupportArticles({ labelName: helpPageName });
    // We try to fetch 3 videos in case there are no articles, but in most cases we'll only use 2.
    // See the buildSupportResources comment for details.
    const videos = await fetchSupportVideos({
        query: `"category: ${helpPageName}"`,
        videosPerPage: 3,
    });
    return {
        articles,
        videos,
    };
};

const totalResourcesToShow = 3;
/**
 * We should show 2 {@link Article articles} and 1 {@link VideoData video} if possible. Otherwise,
 * we attempt to show 3 resources of any type, but accept fewer than 3 if 3 resources don't exist
 * for the current page.
 */
const SupportResources: FC = () => {
    const [videoPlayerTitle, setVideoPlayerTitle] = useState<string | undefined>(undefined);
    const [showVideoPlayer, setShowVideoPlayer] = useState(false);
    const [embeddedVideoURL, setEmbeddedVideoURL] = useState<string | undefined>(undefined);
    const initialData: SupportData = {
        articles: [],
        videos: [],
    };
    const [data, setData] = useState(initialData);
    useEffect(() => {
        setData(initialData);
        fetchSupportResources().then((supportData) => setData(supportData));
        // We do need to check if the page name changes, as the name can be set on a change of
        // URL has (i.e. going from uploads to productions). And, in that case, we'd need to
        // rerender the support resources shown.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [helpPageName]);
    // If there are no videos, we should show 3 articles (if we have 3 to show).
    const articlesToShow = totalResourcesToShow - (data.videos.length === 0 ? 0 : 1);
    const supportRows = [];
    supportRows.push(
        ...data.articles
            .slice(0, articlesToShow)
            .map((article) => (
                <HelpMenuOption
                    label={<SupportRow title={article.title} />}
                    key={article.html_url}
                    selectable={false}
                    onClick={() => window.open(article.html_url, "_blank")}
                />
            )),
    );
    supportRows.push(
        ...data.videos.slice(0, totalResourcesToShow - supportRows.length).map((video) => (
            <HelpMenuOption
                label={<SupportRow title={video.name} isVideo={true} />}
                key={video.link}
                selectable={false}
                onClick={() => {
                    setShowVideoPlayer(true);
                    setEmbeddedVideoURL(`${video.embeddedVideoURL}?autoplay=1`);
                    setVideoPlayerTitle(video.name);
                }}
            />
        )),
    );
    const loadingRows = [0, 1, 2].map((idx) => (
        <HelpMenuOption label={<LoadingRow />} key={idx} selectable={false} />
    ));
    return (
        <>
            <div className="support-resource-container">
                {supportRows.length !== 0 && supportRows}
                {supportRows.length === 0 && loadingRows}
            </div>
            <VideoPlayer
                showPlayer={showVideoPlayer}
                onPlayerClose={() => setShowVideoPlayer(false)}
                embeddedVideoURL={embeddedVideoURL}
                title={videoPlayerTitle}
            />
        </>
    );
};

export const Resources: FC<ResourcesProps> = ({
    onViewAllResourcesClick,
    onContactSupportClick,
}) => {
    return (
        <>
            <SupportResources />
            <HelpMenuOption
                selectable={false}
                label={<div className="bb-text--color-link clickable-row">View all resources</div>}
                onClick={onViewAllResourcesClick}
            />
            <HelpMenuOption
                selectable={false}
                label={<div className="bb-text--color-link clickable-row">Contact Support</div>}
                onClick={onContactSupportClick}
            />
            {/*TODO: delete this option when testing for EP-1548 has wrapped up.*/}
            {!Server.isLive() && (
                <HelpMenuOption
                    selectable={false}
                    label={
                        <div className="bb-text--color-link clickable-row">
                            Delete all recommendations (and clustering model)
                        </div>
                    }
                    onClick={() => {
                        const projectId = Project.CURRENT?.id;
                        if (!projectId) {
                            return;
                        }
                        Rest.post(
                            `/${projectId}/recommendation/deleteAllRecommendations.rest`,
                        ).then(
                            () => {
                                Dialog.ok(
                                    "Recommendations deleted",
                                    "Recommendations deleted successfully!",
                                    () => window.location.reload(),
                                );
                            },
                            () => {
                                Dialog.ok(
                                    "Failed to delete recommendations",
                                    "Check the dev tools console for the error.",
                                );
                            },
                        );
                    }}
                />
            )}
        </>
    );
};
