const SUPPORT_ARTICLE_URL = "https://support.everlaw.com/api/v2/help_center/articles/search.json";
const SUPPORT_VIDEO_URL = "https://api.vimeo.com/me/videos";
const VIMEO_TOKEN = "7f7974e86270d4835de669b4aa70ccb7";
const EMBED_URL = "https://player.vimeo.com/video/";

interface ArticleQuery {
    query?: string;
    labelName?: string;
}

export interface Article {
    body: string;
    html_url: string;
    title: string;
    label_names: string[];
}

export async function fetchSupportArticles(params: ArticleQuery): Promise<Article[]> {
    const articleUrl = new URL(SUPPORT_ARTICLE_URL);
    if (params.query) {
        articleUrl.searchParams.set("query", params.query);
    }
    if (params.labelName) {
        articleUrl.searchParams.set("label_names", params.labelName);
    }
    const response = await fetch(articleUrl);
    // If we run into an error fetching, there's not much we can do, but we should prevent the
    // error from breaking other site functionality.
    if (response.status !== 200) {
        return [];
    }
    const data = await response.json();
    return data.results;
}

interface VideoQuery {
    query: string;
    videosPerPage: number;
}

/**
 * Represents a response from querying {@link SUPPORT_VIDEO_URL}.
 */
export interface VideoData {
    description: string;
    uri: string;
    pictures: {
        sizes: { link: string }[];
    };
    name: string;
    link: string;
    /**
     * This is not sent by {@link SUPPORT_VIDEO_URL}'s API response. Instead, we compute it from
     * {@link uri} after receiving the response.
     */
    embeddedVideoURL: string;
}

export async function fetchSupportVideos(params: VideoQuery): Promise<VideoData[]> {
    const videoUrl = new URL(SUPPORT_VIDEO_URL);
    videoUrl.searchParams.set("query", params.query.toLowerCase());
    videoUrl.searchParams.set("per_page", params.videosPerPage.toString());
    const response = await fetch(videoUrl, { headers: { Authorization: `Bearer ${VIMEO_TOKEN}` } });
    // If we run into an error fetching, there's not much we can do, but we should prevent the
    // error from breaking other site functionality.
    if (response.status !== 200) {
        return [];
    }
    const data = await response.json();
    return data.data.map((videoData: VideoData) => {
        videoData.embeddedVideoURL = `${EMBED_URL}${videoData.uri.split("/")[2]}`;
        return videoData;
    });
}
